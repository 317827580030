<template>
  <div style="width: 100%">
    <div style="padding: 35px; padding-top: 15px">
      <transition name="tour-guide-fade">
        <div
          v-if="showTitles"
          class="py-5 text-2xl font-weight-bold"
        >
          {{ $t('starting_in_just_a_moment') }}
        </div>
      </transition>
      <transition name="tour-guide-fade">
        <div
          v-if="showTitles"
          class="text-xl"
        >
          {{ $t('we_prepare_everything_you_need') }}
        </div>
      </transition>
      <transition name="tour-guide-fade">
        <div
          v-if="showTitles"
          class="text-xl"
        >
          {{ $t('lets_see') }}
        </div>
      </transition>
    </div>
    <transition name="tour-guide-image-fade">
      <div v-if="showTitles">
        <PreloadedImage :src="require('@/assets/images/tour-guide/greenClockVector.png')"></PreloadedImage>
      </div>
    </transition>
  </div>
</template>

<script>
import { useTourGuideTitleAnimation } from '../useTourGuideTitleAnimation'

export default {
  name: 'Welcome',
  props: {
    currentStep: {
      required: true,
    },
    stepIndex: {
      required: true,
    },
  },
  setup(props) {
    const { showTitles } = useTourGuideTitleAnimation(props)

    return {
      showTitles,
    }
  },
}
</script>
